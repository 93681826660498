// Global styles
// These styles are available on all pages.
// If a component has it's own styles, they can be found within the component's respective directory.
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'base';

// @import 'blocks'; // Already imported in ContentWrapper.module.scss
@import 'utilities';
@import 'gpt';
@import 'toolbar';
