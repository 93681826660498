@import 'lib/assets/styles/mixins';

.component {
  :global {
    // Scope WordPress block styles within ContentWrapper.
    @import 'lib/assets/styles/blocks'; /*figure {
      margin-left: 0;
      margin-right: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 4.8rem 0;
    }

    strong {
      font-weight: 700;
    }

    a {
      color: var(--wpe--link--color);
      text-decoration: underline;

      &:hover,
      &:focus {
        color: var(--wpe--link--color);
        text-decoration: none;
      }
    }

    li {
      font-size: 1.6rem;
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }*/

    .alignleft {
      display: inline;
      float: left;
      margin-right: 1.5em;
    }

    .alignright {
      display: inline;
      float: right;
      margin-left: 1.5em;
    }

    .aligncenter {
      clear: both;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    code,
    pre {
      color: var(--color-white);
      background: var(--color-black);
    }

    code {
      padding: 0.25rem 0.5rem;
    }

    pre {
      max-width: 100%;
      overflow: auto;
      padding: 1rem;
    }

    thead th {
      padding-bottom: 0.5em;
    }

    a:not([class]) {
      @apply text-navy hover:text-navy-500 dark:text-silver dark:hover:text-silver-100;
    }

    a u,
    u:has(a) {
      text-decoration: none;
    }

    th {
      padding: 0.4rem 0;
      text-align: left;
    }

    tr {
      border-bottom: 2px dotted var(--mom--color--gray-300);

      [data-theme="dark"] & {
        border-color: var(--mom--color--gray-500);
      }
    }

    td {
      padding: 0.4em;
    }

    li {
      margin: 0 0 0.25em 15px;
      display: list-item;
      line-height: 1.7em;
      word-break: break-word;
    }

    ol {
      counter-reset: list;

      > li {
        margin-bottom: 20px;
        padding-left: 35px;
        text-indent: -35px;

        li {
          text-indent: 0;
        }

        &:before {
          content: counter(list);
          counter-increment: list;
          font-weight: 700;
          border-radius: 50%;
          font-size: 14px;
          border: 1px solid var(--mom--color--navy);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          min-width: 25px;
          text-align: center;
          margin-right: 10px;
          text-indent: 0;

          [data-theme="dark"] & {
            border-color: var(--mom--color--silver);
          }
        }
      }
    }

    ul {
      padding-left: 3px;
      list-style: disc;
      margin: 0 0 20px 20px;

      ul {
        margin-left: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
        list-style-type: circle;

        @include media-breakpoint-up(md) {
          margin-top: 0.25em;
          margin-bottom: 0;
        }
      }

      li {
        list-style-type: disc;

        li {
          list-style-type: circle;

          li {
            list-style-type: square;
          }
        }
      }
    }

    table {
      width: 100%;
      margin-bottom: 1rem;
      background-color: transparent !important;
    }

    td,
    th {
      padding: .75rem;
      vertical-align: top;

      [data-theme="dark"] & {
        border-color: var(--mom--color--gray-500);
      }
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: #3D6D8C26;

        [data-theme="dark"] & {
          background-color: #889EAD26;
        }
      }

      tr:first-child {
        background-color: #3D6D8C4D;

        [data-theme="dark"] & {
          background-color: #889EAD4D;
        }
      }
    }

    thead {
      tr {
        background-color: #3D6D8C4D;

        [data-theme="dark"] & {
          background-color: #889EAD4D;
        }
      }

      + tbody {
        tr:nth-of-type(odd),
        tr:first-child,
        tr {
          background-color: transparent;
        }

        tr:nth-of-type(even) {
          background-color: #3D6D8C26;

          [data-theme="dark"] & {
            background-color: #889EAD26;
          }
        }
      }
    }

    .table-responsive {
      overflow-x: auto;

      table {
        @include media-breakpoint-down(sm) {
          min-width: 700px;
        }
      }
    }

    .rsme-embed {
      position: relative;
      margin: 1.25rem auto;
    }

    .fluid-vids {
      margin-bottom: 1rem;
    }

    .fluid-vids:not(:has(.rll-youtube-player)) {
      width: 100%;
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      background-color: var(--mom--color--offgrey);
      // @include animated-placeholder;

      > div > iframe, // Extra wrapper for lazy load
      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
      }
    }

    .fluid-vids:has(iframe[src*="spotify.com"]) {
      padding-bottom: 45%;
      background-color: transparent;
    }

    .wp-block-video {
      video {
        width: 100%;
        height: auto;
      }
    }

    .wp-block-buttons {
      display: flex;
      margin: 20px 0;
      gap: 10px;
      flex-wrap: wrap;

      & + .wp-block-image {
        margin-top: 60px;
      }
    }

    .wp-element-button {
      height: auto;
      min-height: 2.25rem;
      line-height: 1;
      display: flex;
      align-items: center;
      text-align: center;
    }

    /* .wp-block-button {
      &__link {
        display: block;
        color: var(--mom--color--white) !important;
        background-color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        margin: 0 10px 15px 0;
        padding: 10px 36px;
        text-align: center;
        text-decoration: none;
      }

      &,
      &.is-style-fill {
        .wp-block-button__link {
          background: #000;

          &:hover {
            background-color: var(--mom--color--navy);
            color: var(--mom--color--white) !important;
          }
        }
      }

      &.is-style-outline {
        .wp-block-button__link {
          background-color: transparent;
          border: 1px solid #000;
          color: var(--mom--color--black) !important;

          &:hover {
            background-color: var(--mom--color--navy);
            border-color: var(--mom--color--navy);
            color: var(--mom--color--white) !important;
          }
        }
      }
    } */

    .wp-block-yoast-seo-table-of-contents {
      > h2 {
        display: none;
      }

      /*> h2 {
        cursor: pointer;
        user-select: none;
        margin-bottom: 0;
        padding-bottom: 15px;

        &:before {
          display: inline-block;
          content: "";
          height: 18px;
          width: 18px;
          background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iSXNvbGF0aW9uX01vZGUiIGRhdGEtbmFtZT0iSXNvbGF0aW9uIE1vZGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCA0LjgxIDcuNjgiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0ibGluZWFyLWdyYWRpZW50IiB4MT0iLTQwLjM3IiB5MT0iNy42OCIgeDI9Ii0zOS4zNyIgeTI9IjcuNjgiIGdyYWRpZW50VHJhbnNmb3JtPSJtYXRyaXgoNDUsIDAsIDAsIC00NSwgMTc3Ni4zNywgMzQ5LjM4KSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzI2MjYyZiIvPjxzdG9wIG9mZnNldD0iMC4wMSIgc3RvcC1jb2xvcj0iIzI2MjYyZiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzIzMWYyMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjx0aXRsZT5BcnRib2FyZCAxPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjAuMzQgMC4zNyA0LjA4IDMuODQgMC4zNCA3LjMxIDAuMzQgNy4zMSA0LjA4IDMuODQgMC4zNCAwLjM3IDAuMzQgMC4zNyIgc3R5bGU9ImZpbGw6dXJsKCNsaW5lYXItZ3JhZGllbnQpIi8+PHBvbHlnb24gcG9pbnRzPSIwLjY4IDcuNjggMCA2Ljk1IDMuMzQgMy44NCAwIDAuNzMgMC42OCAwIDQuODEgMy44NCAwLjY4IDcuNjgiIHN0eWxlPSJmaWxsOiMyMzFmMjAiLz48L3N2Zz4=) center / contain no-repeat;
          transform: rotate(90deg);
          transition: transform .2s ease-in-out 0s;
          margin-right: 10px;
        }

        &:hover {
          color: var(--mom--color--navy);

          &:before {
            background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iSXNvbGF0aW9uX01vZGUiIGRhdGEtbmFtZT0iSXNvbGF0aW9uIE1vZGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCA0LjgxIDcuNjgiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0ibGluZWFyLWdyYWRpZW50IiB4MT0iLTQwLjM3IiB5MT0iNy42OCIgeDI9Ii0zOS4zNyIgeTI9IjcuNjgiIGdyYWRpZW50VHJhbnNmb3JtPSJtYXRyaXgoNDUsIDAsIDAsIC00NSwgMTc3Ni4zNywgMzQ5LjM4KSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzI2MjYyZiIvPjxzdG9wIG9mZnNldD0iMC4wMSIgc3RvcC1jb2xvcj0iIzI2MjYyZiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwNGZmMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjx0aXRsZT5BcnRib2FyZCAxPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjAuMzQgMC4zNyA0LjA4IDMuODQgMC4zNCA3LjMxIDAuMzQgNy4zMSA0LjA4IDMuODQgMC4zNCAwLjM3IDAuMzQgMC4zNyIgc3R5bGU9ImZpbGw6dXJsKCNsaW5lYXItZ3JhZGllbnQpIi8+PHBvbHlnb24gcG9pbnRzPSIwLjY4IDcuNjggMCA2Ljk1IDMuMzQgMy44NCAwIDAuNzMgMC42OCAwIDQuODEgMy44NCAwLjY4IDcuNjgiIHN0eWxlPSJmaWxsOiMwMDRmZjIiLz48L3N2Zz4K);
          }
        }
      }*/

      > ul {
        //background: var(--wpe--color--offwhite);
        //padding: 15px;
        //font-size: 14px;
        //font-weight: 500;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(sm) {
          display: block !important;
        }

        > li {
          list-style: none;
          margin-left: 0;
        }

        ul {
          padding: 0;

          li {
            list-style-type: circle;
            margin-bottom: 10px !important;
            margin-left: 20px !important;

            @include media-breakpoint-up(md) {
              margin-bottom: 5px !important;
            }
          }
        }
      }

      a {
        text-decoration: none;
        display: block;

        &:not([class]) {
          @apply text-black hover:text-navy-500 dark:text-white dark:hover:text-silver-100 transition-none;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .wp-block-yoast-faq-block {
      .schema-faq-section {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        background: #fff;
        margin: 1em auto;
        width: 100%;
        overflow: hidden;
        padding: 1em;
        box-sizing: border-box;

        [data-theme="dark"] & {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
          background: #1a1a1a;
        }
      }

      .schema-faq-question {
        display: block;
        font-size: 1em;
        line-height: 1.2em;
        margin: 0 0 1em;
        font-weight: 700;
      }

      .schema-faq-answer {
        margin: 0;
      }
    }

    .wp-block-image {
      margin-bottom: 20px;

      &.size-full {
        height: auto; // override default Tailwind height of 100%
      }

      &.size-overflow {
        @include media-breakpoint-up(xl) {
          margin-left: -140px;
          margin-right: -140px;
          max-width: calc(100% + 280px);
          width: calc(100% + 280px);
        }
      }
    }

    .wp-block-gallery {
      display: grid !important;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 30px !important;
      margin: 30px 0;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      .wp-block-image {
        grid-column: span 1 / span 1;
        margin-bottom: 0;
        width: 100% !important;
      }

      .img-block {
        margin: 0;
      }

      &.columns-8 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(8, minmax(0, 1fr));
        }
      }

      &.columns-7 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(7, minmax(0, 1fr));
        }
      }

      &.columns-6 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(6, minmax(0, 1fr));
        }
      }

      &.columns-5 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
      }

      &.columns-4 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }

      &.columns-3 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }

      &.columns-2 {
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }

    .wp-block-media-text {
      .wp-block-media-text__content {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .wp-element-caption,
    .wp-caption-text {
      font-size: 12px;
      margin: 10px 0 15px;
      line-height: 1.3;
      text-align: left;
    }

    .ch_custom {
      margin-bottom: 20px;
      margin-right: 15px;
    }

    .bimg-load {
      display: block;
      height: 100%;
      @include img-ratio(4, 3);
    }

    .schema-faq {
      .mom-gpt__wrapper {
        display: none;
      }
    }

    .wp-video {
      max-width: 100%;
      margin-bottom: 15px;

      video {
        width: 100%;
        height: auto;
      }
    }
  }

  :global(.mom-featured) & {
    :global {
      // Featured layout content ported over
      // TODO: shorten these
      .l-box p,
      .r-box p {
        font-size: 20px
      }

      .wrapper {
        margin: 40px auto 60px;
        position: relative;
        max-width: 1200px;
        padding: 0 20px;
      }

      .full_copy.container {
        max-width: 800px;
      }

      .post-block {
        @include media-breakpoint-up(md) {
          margin: 0 -10px;
        }
      }

      .two_copy_block .post-block {
        display: flex
      }

      @include media-breakpoint-down(sm) {
        .two_copy_block .post-block {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column
        }
      }

      .two_copy_block .post-block .copy-box {
        width: 50%;
        padding: 0 10px
      }

      @include media-breakpoint-down(sm) {
        .two_copy_block .post-block .copy-box {
          width: 100%;
          padding: 0
        }
      }

      .three_copy_block .post-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
      }

      @include media-breakpoint-down(sm) {
        .three_copy_block .post-block {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column
        }
        .site-footer .info-box {
          margin-bottom: 0 !important;
        }
      }

      .three_copy_block .post-block .copy-box {
        width: 33.33%;
        padding: 0 10px
      }

      @include media-breakpoint-down(sm) {
        .three_copy_block .post-block .copy-box {
          width: 100%;
          padding: 0;
        }
        .three_copy_block .post-block .copy-box img {
          width: 100%;
        }
      }

      .container {
        margin-bottom: 60px
      }

      @include media-breakpoint-down(sm) {
        .full_copy .container {
          margin-bottom: 0 !important
        }
      }

      .three_images_block .post-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
      }

      @include media-breakpoint-down(sm) {
        .three_images_block .post-block {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column
        }
      }

      .three_images_block .post-block .image-box {
        width: 33.33%;
        padding: 0 10px
      }

      @include media-breakpoint-down(sm) {
        .three_images_block .post-block .image-box {
          width: 100%;
          padding: 0 0 20px
        }

        .three_images_block {
          margin-bottom: 0 !important
        }

        .full_copy {
          margin: 0
        }

        .three_copy_block {
          margin-bottom: 0
        }

        .copy_quote_block {
          margin-bottom: 0
        }
      }

      .copy_quote_block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
      }

      @include media-breakpoint-down(sm) {
        .copy_quote_block {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column
        }
      }

      .copy_quote_block .l-box,
      .copy_quote_block .r-box {
        padding: 0
      }

      @include media-breakpoint-down(sm) {
        .copy_quote_block .l-box,
        .copy_quote_block .r-box {
          width: 100%;
          padding-bottom: 30px
        }
      }

      .copy_quote_block .l-box .copy-box {
        max-width: 700px
      }

      @include media-breakpoint-down(sm) {
        .copy_quote_block .l-box .copy-box {
          max-width: 100%
        }
      }

      .copy_quote_block .l-box .copy-box h4 {
        font-size: 14px;
        margin-bottom: 30px
      }

      .copy_quote_block .l-box .copy-box p {
        font-size: 16px;
        line-height: 26px
      }

      .copy_quote_block .l-box .copy-box p:last-child {
        margin: 0
      }

      .copy_quote_block .r-box .r-box-copy {
        border-left: 1px solid var(--mom--color--grey);
        padding: 0 40px 34px 40px;
        font-style: italic;
        font-size: 24px;
        line-height: 34px;
      }

      .copy_quote_block.right-left .l-box {
        float: none;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
      }

      .copy_quote_block.right-left .l-box .copy-box {
        float: right
      }

      .copy_quote_block.right-left .r-box {
        float: none;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
      }

      .copy_quote_block.right-left .r-box .r-box-copy {
        border-left: 0;
        border-right: 1px solid var(--mom--color--grey)
      }

      .copy_image_block {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
      }

      @include media-breakpoint-down(sm) {
        .copy_image_block {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column
        }
      }

      .copy_image_block.right-left .l-box {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
      }

      .copy_image_block.right-left .r-box {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
      }

      .copy_image_block .l-box,
      .copy_image_block .r-box {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        padding: 0
      }

      @include media-breakpoint-down(sm) {

        .copy_image_block .l-box,
        .copy_image_block .r-box {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%
        }
      }

      @include media-breakpoint-down(sm) {
        .copy_image_block .l-box {
          -webkit-box-ordinal-group: 2;
          -webkit-order: 1;
          -ms-flex-order: 1;
          order: 1
        }
      }

      .copy_image_block .r-box {
        padding: 120px 80px;
        background: #000;
        color: #fff
      }

      .copy_image_block .r-box h4 {
        margin-bottom: 30px;
        font-size: 14px;
        color: var(--mom--color--grey)
      }

      @include media-breakpoint-down(sm) {
        .copy_image_block .r-box {
          -webkit-box-ordinal-group: 3;
          -webkit-order: 2;
          -ms-flex-order: 2;
          order: 2;
          padding: 20px
        }
      }

      .copy_image_block .l-box .post-image {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .wp-block-image {
        img {
          margin-left: auto;
          margin-right: auto;
        }

        &.size-full img {
          max-width: none;
          width: 100%;
        }
      }
    }
  }
}
