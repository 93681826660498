@import 'lib/assets/styles/mixins';

.component {
  text-decoration: none;
  margin-top: 25px;
  margin-bottom: 45px;

  @include media-breakpoint-up(md) {
    margin-top: 45px;
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-gap: 32px;
  }
}

.desc {
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 28px;
  }
}

.avatar {
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
}

.info {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  :global(.mom-author-table) {
    width: 100%;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    p {
      margin-top: 0;
      margin-bottom: 10px;
      line-height: 1.3;

      &:last-child {
        margin-bottom: 0;
      }
    }

    th, td {
      padding: 10px 0 5px;
      text-align: left;
      vertical-align: top;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    th {
      padding-right: 10px;
      width: 100%;
      padding-top: 10px;

      @include media-breakpoint-up(md) {
        width: 165px;
        padding-right: 30px;
      }
    }

    td {
      @include media-breakpoint-down(sm) {
        padding-top: 5px;
        padding-bottom: 15px;
      }
    }

    li {
      list-style-type: disc;
      margin: 0 0 0 18px;
      display: list-item;
      line-height: 26px;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 1;
}

.heading {
  margin: 5px 0 20px;
}
