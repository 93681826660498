:root {
  // Color Definitions.
  --mom--color--black: #000;
  --mom--color--white: #fff;
  --wpe--color--offwhite: #f7f7f7;
  --mom--color--navy: #3D6D8C;
  --mom--color--navy-300: rgba(61, 109, 140, 0.15);
  --mom--color--navy-400: #1b5479;
  --mom--color--navy-500: #1c3d53;
  --mom--color--silver: #889EAD;
  --mom--color--silver-100: #bbc4ca;
  --mom--color--silver-200: #84A9C2;
  --mom--color--silver-300: rgba(136, 158, 173, 0.15);
  --mom--color--gray-200: #f1f1f1;
  --mom--color--gray-300: #C9C9C9;
  --mom--color--gray-500: #515151;

  --mom--color--orange: #ef5532;
  --mom--color--offorange: #ff8468;
  --mom--color--grey: #737677;
  --mom--color--offgrey: #dbdbdb;
  --mom--color--offgrey-alt: #edeff1;
  --mom--color--neutral: #363636;
  --mom--color--offneutral: #2b3237;
  --wpe--color--slate: #0a2540;

  // Color Assignments.
  --wpe--color--accent: var(--mom--color--navy);
  --wpe--color--accent--dark: var(--wpe--color--slate);
  --wpe--color--title: var(--mom--color--black);
  --wpe--link--color: var(--wpe--color--accent);
  --wpe--code--color: var(--mom--color--black);
  --wpe--code--background-color: var(--mom--color--neutral);
  --wpe--header--color: var(--wpe--color--slate);
  --wpe--header--background-color: var(--mom--color--white);
  --wpe--navigation-menu--color: var(--mom--color--black);
  --wpe--navigation-menu--color--hover: var(--wpe--color--accent);
  --wpe--navigation-menu--background-color: transparent;
  --wpe--dropdown--background-color: var(--mom--color--white);
  --wpe--main--color: var(--mom--color--black);
  --wpe--main--background-color: var(--mom--color--white);
  --wpe--footer--color: var(--mom--color--black);
  --wpe--footer--background-color: var(--mom--color--white);
  --wpe--heading--color: var(--wpe--color--accent);
  --wpe--border--light: 1px solid var(--mom--color--neutral);
  --wpe--border--extra-light: 1px solid var(--wpe--color--offwhite);

  // Type.
  --wpe--font-weight--light: 300;
  --wpe--font-weight--normal: 400;
  --wpe--font-weight--medium: 500;
  --wpe--font-weight--bold: 700;

  --mom--font-family--heading: 'Bradford', serif;
  --mom--font-family--body: 'GT America', sans-serif;

  // Spacing.
  --mom--content--max-width: 1000px;
  --wpe--section--padding-y: 3rem;
  --mom--container--padding-x: 15px;
  --mom--container--max-width: 1200px;
  --mom--button--min-width: 130px;
  --mom--header-height: 87px;
  --mom--header-height-mini: 87px;
  --mom--header-subnav-height: 0px;
  --mom--header-admin-bar-height: 0px;
  --mom--offset-top: calc(var(--mom--header-height) + var(--mom--header-subnav-height) + var(--mom--header-admin-bar-height));
  --mom--offset-top-mini: calc(var(--mom--header-height-mini) + var(--mom--header-subnav-height) + var(--mom--header-admin-bar-height));
  --mom--standard-radius: 6px;
  --mom--standard-gap: 25px;
  --mom--sidebar: 300px;
  --mom--hr: 20px;
  --mom--header-overlay-offset: 0;

  @include media-breakpoint-up(md) {
    --mom--standard-gap: 50px;
    --mom--container--padding-x: 20px;
    --mom--header-height: 102px;
  }

  @include media-breakpoint-up(lg) {
    --mom--header-height: 116px;
    --mom--header-overlay-offset: -20%;
  }

  @include media-breakpoint-up(xl) {
    --mom--header-height: 148px;
  }

  // Shadows.
  --wpe--shadow--large: 0 50px 120px -40px rgba(150, 150, 150, 0.2), 0 20px 60px -30px rgba(0, 0, 0, 0.5);

  &:has(.admin-bar) {
    @include media-breakpoint-up(md) {
      --mom--header-admin-bar-height: 32px;
    }

    @media screen and (max-width: 782px) {
      --wp-admin--admin-bar--height: 46px;
      --mom--header-admin-bar-height: 46px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold font-serif mb-5 leading-[1.2] last:mb-0;

  em,
  i {
    font-weight: 300;
  }
}

h1 {
  @apply text-h1;
}

h2 {
  @apply text-h2;
}

h3 {
  @apply text-h3;
}

h4 {
  @apply text-h4;
}

h5 {
  @apply text-h5
}

h6 {
  @apply text-h6;
}

p {
  @apply mb-5 last:mb-0;
}

a {
  @apply underline hover:no-underline hover:text-navy dark:hover:text-silver transition-colors;
}

hr {
  @apply my-5 border-0 mom-border border-t-2;
}

figure {
  @apply ml-0 mr-0 mt-0 block w-full relative;
}

svg:not(.lucide) {
  fill: currentColor !important;

  circle,
  path {
    fill: currentColor !important;
  }
}

button,
input,
select,
textarea {
  @apply font-sans m-0;
}

// Need to be declared separately to avoid conflict with the selection styles. Do not combine with ::selection.
::-moz-selection {
  @apply bg-navy text-white dark:bg-silver dark:text-white;
}

::selection {
  @apply bg-navy text-white dark:bg-silver dark:text-white;
}

[id] {
  scroll-margin-top: 50px; /* Adjust this value based on your header height + desired padding */

  @include media-breakpoint-up(md) {
    scroll-margin-top: 70px;
  }
}

.pushengage-subscription-reminder-prompt__button,
[class*="pushengage-opt-in"][class*="__button__"] {
  border-radius: 20px !important;
}
