@import 'lib/assets/styles/mixins';

// Avoid using "Ad" or "Ads" to prevent ad blockers from detecting the ads

.mom-gpt {
  &__wrapper {
    margin-top: 30px;
    margin-bottom: 45px;

    @include media-breakpoint-up(md) {
      margin-top: 45px;
      margin-bottom: 60px;
    }

    &:has([id^="gam-wrapper"][style="display: none;"]), // Hide ads if no ads loaded
    &:has([id^="gam-wrapper"] iframe[style*="height: 1px"]) { // Hide ads if there's a Kargo ads replaced
      display: none;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    /*&:not(:has([id^="google_ads_iframe"])):before {
      content: "";
      display: block;
      width: 44px;
      height: 44px;
      margin-left: -22px;
      margin-top: -22px;
      border-radius: 50%;
      border: 15px solid #f3f3f3;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
      z-index: 0;
      @include absolute-center;
    }*/

    &--labeled {
      &.mom-gpt__inner--labeled--top [data-google-query-id]::before {
        content: "ADVERTISEMENT - CONTINUE READING BELOW";
        color: #bcbfc5;
        font-size: 9px;
        letter-spacing: 1px;
        line-height: 20px;
        height: 20px;
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 5px;
      }

      &.mom-gpt__inner--labeled--bottom [data-google-query-id]::after {
        content: "ADVERTISEMENT";
        color: #bcbfc5;
        font-size: 9px;
        letter-spacing: 1px;
        line-height: 20px;
        height: 20px;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        display: block;
        text-align: center;
      }
    }

    /*.home-post &,
    .archive .list-layout &,
    .single-post .entry-content & {
      @media only screen and (max-width: 767px) {
        justify-content: start;
      }
    }*/

    /*.single-post .sidebar-container .widget & {
      margin-bottom: 15px;
    }*/

    /*> div {
      .home-post &,
      .archive .list-layout &,
      .single-post .entry-content & {
        @media only screen and (max-width: 767px) {
          position: sticky;
          top: 70px;
        }
      }
    }*/

    [id^="google_ads_iframe"] {
      overflow: hidden;
    }

    iframe {
      @apply inline-block;
    }
  }

  &__dismiss {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    padding: 5px;
    border: 0;
    width: 40px;
    height: 40px;
    /* opacity: 0.2; */
    z-index: 1;

    [data-theme="dark"] & {
      filter: invert(1) hue-rotate(180deg);
    }

    &:hover {
      opacity: 1;
    }

    .mom-sticky-gpt--dismissed & {
      display: none;
    }
  }

  &__1x1 {
    position: fixed;
    top: 0; /* So it can be visible right away */
  }
}

.mom-sticky-gpt {
  $mom-sticky-gpt: &;

  &__mobile--top {
    position: sticky;
    top: 0;
    z-index: 10;
    transition: transform .2s ease-in-out 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff;
    border-bottom: 1px solid #dbdbdb;

    &.sticky--disabled {
      transform: translateY(-101%);
    }

    .single & {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__desktop--bottom {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out 0s;
  }

  &__desktop--bottom,
  &__mobile--bottom {
    position: sticky;
    bottom: 0;
    z-index: 10;
    /* transition: none; */

    body:has(.kargo-creative) &, // Hide ads if there's a Kargo ads replaced
    &:has(script) {
      display: none;
    }

    &:has(iframe) {
      background-color: rgba(#fff, 0.85);

      [data-theme="dark"] & {
        background-color: rgba(#000, 0.85);
      }
    }
  }

  &__desktop--top {
    position: sticky;
    top: 0;
    padding-top: 8px;
    /*padding-bottom: 5px;*/
    background-color: #fff;
    z-index: 11;
    transition: transform .2s ease-in-out 0s;

    &.sticky--disabled {
      transform: translateY(-101%);
    }
  }

  &--dismissed {
    position: relative !important;

    /*&.mom-sticky-gpt__desktop--bottom {
      body:not(.single) & {
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }*/
  }

  &--visible {
    opacity: 1 !important;
    visibility: visible !important;
  }

  /*.mom-gpt__inner:before {
    content: none !important; // Disable loading icon
  }*/
}

.genecyAd-welcomer-eCont:not(:has(+ #__next)) {
  /* Hide duplicate ads, except the last one */
  position: absolute !important;
  overflow: 0;
  visibility: hidden;
}

body:has(.genecyAd-wallpaper-eCont) #main-content .container,
body:has(.genecyAd-backgrounder-eCont) #main-content .container {
  @apply bg-white dark:bg-black;
}
